<template>
  <div v-if="list.length">
    <div class="evet_table_box platform-overflow">
      <table class="evet_table">
        <thead class="table-title">
          <tr>
            <th>{{ curPropertyName }}</th>
            <th>总数</th>
            <th v-for="item in chartXdata" :key="'ld_' + item">{{ item }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="'pName_' + index"
            :class="[(index % 2 === 0) ? 'table-row-1' : 'table-row-2', 'text-center']">
            <td>{{ item.name }}</td>
            <td>{{ item.total }}</td>
            <td v-for="child in chartXdata" :key="'td_' + index + '_' + child">{{ formatTd(item.dateStatList, child) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="listQuery.pageSize" show-total show-elevator
        :current="listQuery.pageNumber" @on-change="onChangePage"></Page>
    </div>
  </div>
</template>

<script>
import { getPage } from '@/api/event/analysis'
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
    chartXdata: {
      type: Array,
      default: new Array(0)
    },
    curPropertyName: {
      type: String,
      default: '未知'
    }
  },
  data () {
    return {
      listQuery: {
        pageNumber: 1,
        pageSize: 15
      },
      list: [],
      total: 0
    }
  },
  methods: {
    /**
     * 获取列表数据
     */
    getTableData (isFirstPage = true) {
      if (!this.query.propertyId) {
        return false
      }
      this.listQuery.pageNumber = isFirstPage ? 1 : this.listQuery.pageNumber
      const query = { ...this.query, ...this.listQuery }
      getPage(query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.listQuery.pageNumber = res.pageNumber
          this.total = res.totalRow
        } else {
          this.list = []
          this.listQuery.pageNumber = 1
          this.total = 0
        }
      })
    },
    formatTd (dataList = [], date = '') {
      if (dataList.length && date !== '') {
        const data = dataList.find(x => x.dateStr === date)
        return data ? data.count : 0
      } else {
        return 0
      }
    },
    onChangePage (curPage) {
      this.listQuery.pageNumber = curPage
      this.getTableData(false)
    }
  }
}
</script>
<style lang="less" scoped>
.evet_table_box {
  width: 100%;
  max-height: 480px;
  overflow: auto;
}

.evet_table {
  min-width: 100%;
  border-collapse: collapse;

  th,
  td {
    height: 32px;
    line-height: 32px;
    min-width: 80px;
  }
}
</style>
