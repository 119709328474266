import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取事件分析列表
 * @param {Object} data
 * @returns
 */
export function getList (data) {
  return request.post('/ooh-event/v1/analysis/getList', qs.stringify(data))
}

/**
 * 分页获取事件分析列表
 * @param {Object} data
 * @returns
 */
export function getPage (data) {
  return request.post('/ooh-event/v1/analysis/getPage', qs.stringify(data))
}
