<style lang="less" scoped>
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
<template>
  <div>
    <div class="m-b-10">选择事件进行分析</div>
    <div class="m-b-10">
      <Select v-model="eventId" size="small" style="width:200px" filterable placeholder="选择事件"
        @on-change="onChagneEvent()">
        <Option v-for="item in eventArray" :key="'event_' + item.id" :value="item.id">{{ item.eventName }}
        </Option>
      </Select>
      <span class="p-l-10 p-r-10">的</span>
      <Select v-model="query.indexType" :disabled="!eventId" size="small" style="width:200px" placeholder="选择指标类型"
        @on-change="onChangeNormType">
        <Option v-for="item in normTypeArray" :key="'normType_' + item.id" :value="item.id">
          {{ item.name }}
        </Option>
      </Select>
    </div>
    <div class="m-b-10">
      <span class="p-r-10">按</span>
      <Select v-model="query.propertyId" :disabled="!eventId" size="small" style="width:200px" filterable
        placeholder="选择属性" @on-change="onChangeProperty">
        <Option v-for="item in propertyArray" :key="'property_' + item.id" :value="item.id">{{ item.propertyName }}
        </Option>
      </Select>
      <span class="p-l-10">查看</span>
    </div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="2">
        <RadioGroup v-model="query.groupType" size="small" type="button" button-style="solid"
          @on-change="onChangeDateType">
          <Radio v-for="item in dateTypeArray" :key="'dType_' + item.value" :label="item.value">{{ item.name }}</Radio>
        </RadioGroup>
      </i-col>
      <i-col span="3">
        <DatePicker v-model="date" type="daterange" size="small" transfer :clearable="false" :options="dateOptions"
          placement="bottom-end" placeholder="选择日期" style="width:100%" @on-change="onChangeDate"></DatePicker>
      </i-col>
      <i-col span="15"></i-col>
      <i-col span="4" class="text-right">
        <RadioGroup v-model="chartType" size="small" type="button" @on-change="onChangeCahrtType">
          <Radio v-for="item in chartTypeArray" :key="'ct_' + item.value" :label="item.value">
            <Icon :type="item.icon" size="20"></Icon>
          </Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <div style="position: relative; height:560px">
      <div class="m-b-20">
        <div v-show="showChart" class="p-t-20" style="min-width:100%;height:560px; overflow-x: auto;" id="chart">
        </div>
        <div v-show="!showChart" class="default-content">
          <p style="font-size: x-large;">暂无数据</p>
        </div>
      </div>
      <BehaviorStatList ref="statList" :query="query" :chartXdata="chartXdata" :curPropertyName="curPropertyName" />
      <Spin v-if="dataLoading" fix size="large">
        <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
        <div>数据分析中...</div>
      </Spin>
    </div>
  </div>
</template>

<script>
import { ParseDate, ParseDateForMonth, getWeekFirstDay } from '@/utils/dateFormat'
import { sysMixins } from '@/assets/mixins/sys'
import { caseLibraryStatMixins } from '../mixins/statistics'
import { getEventList, getEventPropertiesList } from '@/api/event/open'
import { getList } from '@/api/event/analysis'
import BehaviorStatList from './statistics/BehaviorStatList.vue'
export default {
  mixins: [sysMixins, caseLibraryStatMixins],
  components: { BehaviorStatList },
  data () {
    return {
      eventArray: [],
      normTypeArray: [
        { id: 1, name: '总次数' },
        { id: 2, name: '用户数' }
      ],
      propertyArray: [],
      dateTypeArray: [
        { value: 1, name: '天' },
        { value: 2, name: '周' },
        { value: 3, name: '月' }
      ],
      eventId: undefined,
      dateOptions: {
        shortcuts: [
          {
            text: '近一周',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            }
          },
          {
            text: '近一个月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            }
          },
          {
            text: '近三个月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            }
          }
        ]
      },
      date: [],
      query: {
        eventCode: '',
        indexType: undefined,
        propertyId: undefined,
        groupType: 1,
        startDate: '',
        endDate: ''
      },
      showChart: false,
      curPropertyName: '',
      chartXdata: [],
      chartType: 'line',
      chartTypeArray: [
        { value: 'line', icon: 'ios-pulse' },
        { value: 'bar', icon: 'ios-stats' },
        { value: 'pie', icon: 'ios-pie' }
      ],
      dataLoading: false
    }
  },
  created () {
    // 默认日期为近一个月
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    this.date = [ParseDate(start), ParseDate(end)]
    this.query.startDate = this.date[0]
    this.query.endDate = this.date[1]
    this.getEventData()
  },
  methods: {
    getEventData () {
      this.eventArray = []
      getEventList().then(res => {
        if (res && !res.errcode) {
          this.eventArray = res
          this.eventId = res.length ? res[0].id : undefined
          this.query.indexType = this.normTypeArray[0].id
          this.onChagneEvent()
        }
      })
    },
    onChagneEvent () {
      this.query.propertyId = undefined
      this.getPropertyData()
    },
    /**
     * 切换指标类型
     */
    onChangeNormType () {
      this.initData()
      this.$refs.statList.getTableData()
    },
    /**
   * 获取事件下的属性数据
   */
    getPropertyData () {
      this.propertyArray = []
      getEventPropertiesList({ eventId: this.eventId }).then(res => {
        if (res && !res.errcode) {
          this.propertyArray = res
          this.query.propertyId = res.length ? res[0].id : undefined
          this.curPropertyName = res.length ? res[0].propertyName : ''
          this.initData()
          this.$refs.statList.getTableData()
        }
      })
    },
    /**
     * 切换查看属性
     */
    onChangeProperty () {
      const property = this.propertyArray.find(x => x.id === this.query.propertyId)
      this.curPropertyName = property ? property.propertyName : ''
      this.initData()
      this.$refs.statList.getTableData()
    },
    onChangeDateType () {
      this.initData()
      this.$refs.statList.getTableData()
    },
    onChangeDate (date) {
      this.query.startDate = date[0]
      this.query.endDate = date[1]
      this.initData()
      this.$refs.statList.getTableData()
    },
    onChangeCahrtType () {
      this.initData()
      this.$refs.statList.getTableData()
    },
    /**
     * 组装图表x轴数据，折线图和柱状图使用
     */
    assembleXdata () {
      const xData = []
      var date = new Date(this.query.startDate)
      switch (this.query.groupType) {
        case 1: // 天
          xData.push(this.query.startDate)
          while (date < new Date(this.query.endDate)) {
            date.setDate(date.getDate() + 1)
            xData.push(ParseDate(date))
          }
          break
        case 2: // 周
          var diffTime = new Date(this.query.endDate).getTime() - new Date(this.query.startDate).getTime()
          var diffDay = (diffTime / (1000 * 3600 * 24)) + 1
          var totalWeek = Math.ceil((diffDay / 7))
          var sDate = ''
          var eDate = ''

          for (let i = 0; i < totalWeek; i++) {
            if (i === 0) {
              sDate = new Date(getWeekFirstDay(this.query.startDate))
            } else {
              sDate = eDate.setDate(eDate.getDate() + 1)
            }
            var startDate = new Date(sDate)
            eDate = new Date(startDate.setDate(startDate.getDate() + 6))
            xData.push(`${ParseDate(sDate)}至${ParseDate(eDate)}`)
          }
          break

        case 3: // 月
          xData.push(ParseDateForMonth(date))
          // 将开始日期设置为1号
          date.setDate(1)
          while (date < new Date(ParseDateForMonth(this.query.endDate))) {
            date.setMonth((date.getMonth() + 1))
            xData.push(ParseDateForMonth(date))
          }
          break
      }
      this.chartXdata = xData
    },
    /**
     * 获取图表数据并生成图表
     */
    async initData () {
      this.showChart = false
      if (!this.query.propertyId) {
        return false
      }
      this.dataLoading = true
      this.assembleXdata()
      // 获取图表数据
      const event = this.eventArray.find(x => x.id === this.eventId)
      this.query.eventCode = event ? event.eventCode : ''
      const result = await getList(this.query).finally(() => { this.dataLoading = false })
      // 定义图表默认配置项
      const chartOption = {
        title: {
          text: `按${this.curPropertyName}查看`,
          left: 'left',
          textStyle: {
            color: this.reportFontColor
          }
        },
        textStyle: {
          color: this.reportFontColor
        },
        legend: {
          data: result.map(x => x.name),
          textStyle: {
            color: this.reportFontColor
          }
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {
              title: '保存为图片',
              type: 'png',
              name: '行为分析',
              backgroundColor: 'auto'
            }
          },
          iconStyle: {
            borderColor: this.reportFontColor
          }
        }
      }
      // 自定义图表配置项
      let defineOptions = {}

      if (result && result.length) {
        this.showChart = true
        const seriesData = []
        if (this.chartType === 'pie') {
          // 饼图配置项处理
          result.forEach(item => {
            const newItem = {
              value: item.total,
              name: item.name
            }
            seriesData.push(newItem)
          })
          defineOptions = {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '',
                type: 'pie',
                radius: ['20%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: true,
                  formatter (param) {
                    // correct the percentage
                    return param.name + ' (' + param.percent + '%)'
                  }
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 40,
                    fontWeight: 'bold'
                  }
                },
                labelLine: {
                  show: true
                },
                data: seriesData
              }
            ]
          }
        } else {
          // 折线图和柱状图配置项处理
          result.forEach(item => {
            // 查询对应日期的数据
            const data = []
            this.chartXdata.forEach(x => {
              const tempData = item.dateStatList.find(t => t.dateStr === x)
              data.push(tempData ? tempData.count : 0)
            })
            // 动态生成图标的series数据格式
            const newItem = {
              name: item.name,
              type: this.chartType,
              stack: this.chartType === 'bar' ? 'total' : undefined,
              data: data
            }
            seriesData.push(newItem)
          })

          defineOptions = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: [
              {
                type: 'category',
                data: this.chartXdata,
                axisTick: {
                  alignWithLabel: true
                }
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: seriesData
          }
        }
      }
      // 组合配置项
      Object.assign(chartOption, defineOptions)

      this.initChart('chart', chartOption, this.showChart)
    }

  }
}
</script>
