import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取已添加的事件列表
 * @param {Object} data
 * @returns
 */
export function getEventList (data) {
  return request.post('/ooh-event/v1/open/getEventList', qs.stringify(data))
}

/**
 * 获取事件下得属性集合
 * @param {Object} data
 * @returns
 */
export function getEventPropertiesList (data) {
  return request.post('/ooh-event/v1/open/getEventPropertiesList', qs.stringify(data))
}
